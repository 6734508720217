import { useI18n } from '@braincube/i18n';
import { EmptyContentMessage, advancedTextSearch } from '@braincube/ui';
import React, { useCallback, useMemo } from 'react';

import useProductOffers from 'components/Content/services/useProductOffers';
import { DA_CATEGORY } from 'services/constants/categories';
import { useFilters } from 'services/context/Filters';
import useDigitalAssistantTemplates from 'services/hooks/react-query/useDigitalAssistantTemplates';
import useGetAvailableApplications from 'services/hooks/useGetAvailableApplications';
import useGetInstalledApplicationsUuid from 'services/hooks/useGetInstalledApplicationsUuid';
import { useHeader } from 'services/hooks/useHeader';

import AvailableDigitalAssistant from './components/AvailableDigitalAssitant';
import NotInstalledApplication from './components/NotInstalledApplication';

function AvailableApplications() {
    const i18n = useI18n();
    const { accessList, selectedProductUuid } = useHeader();

    const { data: digitalAssistantTemplates = [] } = useDigitalAssistantTemplates(selectedProductUuid);
    const { textFilter, categoryFilter } = useFilters();
    const availableApplications = useGetAvailableApplications();
    const installedApplicationsUuid = useGetInstalledApplicationsUuid();
    const productOffers = useProductOffers();

    const checkIfAppIsNotInOffer = useCallback(
        /*
         * An application is not in offer if :
         * - it is not a digital assistant
         * - It is not a custom app
         * - It is not in the current product offers
         */
        (app) =>
            app.category !== DA_CATEGORY && !app.custom && !productOffers.find((offer) => offer.apps.includes(app.id)),
        [productOffers],
    );

    const selectedProductType = useMemo(
        () => accessList.find(({ product }) => product.productId === selectedProductUuid).product.type,
        [accessList, selectedProductUuid],
    );

    const displayedApplications = useMemo(() => {
        return availableApplications
            .filter((app) => {
                if (selectedProductType === 'braincube') {
                    return app.productsTarget?.includes('BRAINCUBE');
                }
                return app.productsTarget?.includes('IOT');
            })
            .filter((app) => app.reachable === true)
            .filter((app) => installedApplicationsUuid.indexOf(app.id) === -1); // Hide applications that are already installed
    }, [availableApplications, selectedProductType, installedApplicationsUuid]);

    const displayedDigitalAssistants = useMemo(() => {
        if (selectedProductType === 'iot') {
            return digitalAssistantTemplates;
        }
        return [];
    }, [digitalAssistantTemplates, selectedProductType]);

    /**
     * All applications and DA displayed in the list.
     * Filtered based on text and category filters and sorted by name.
     * Applications not in any offer are displayed at the end of the list.
     */
    const finalAppList = useMemo(() => {
        const allApps = [...displayedApplications, ...displayedDigitalAssistants];

        return advancedTextSearch(textFilter, allApps, ['name', 'description'])
            .filter((app) => {
                return categoryFilter === 'all' || app.category === categoryFilter;
            })
            .sort((a, b) => {
                return checkIfAppIsNotInOffer(a) - checkIfAppIsNotInOffer(b) || a.name.localeCompare(b.name);
            });
    }, [displayedApplications, displayedDigitalAssistants, textFilter, categoryFilter, checkIfAppIsNotInOffer]);

    if (finalAppList.length === 0) {
        return textFilter ? (
            <EmptyContentMessage
                type="NO_RESULT"
                title={i18n.tc('allApplication.noResult.title')}
                subtitle={i18n.tc('allApplication.noResult.subtitle')}
            />
        ) : (
            <EmptyContentMessage
                type="NO_DATA"
                title={i18n.tc('allApplication.noData.title')}
                subtitle={i18n.tc('allApplication.noData.subtitle')}
            />
        );
    }

    return finalAppList.map((app) =>
        app.category === DA_CATEGORY ? (
            <AvailableDigitalAssistant key={app.id} digitalAssistant={app} />
        ) : (
            <NotInstalledApplication key={app.id} app={app} isNotInOffer={checkIfAppIsNotInOffer(app)} />
        ),
    );
}

export default AvailableApplications;
